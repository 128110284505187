<template>
  <b-modal
    :id="data.id"
    :title="data.title"
    ok-title="Simpan"
    @ok.prevent="validationForm()"
    cancel-title="Batal"
    title-tag="h4"
    cancel-variant="outline-danger"
    ok-variant="info"
    no-close-on-backdrop
  >
    <validation-observer ref="keywordForm">
      <b-form>
        <!-- MEDIA NAME -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Group"
            rules="required"
          >
          <b-form-input
              v-model="name"
              type="text"
              :placeholder="'Masukkan Nama Media'"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- MEDIA TYPE -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Jenis Media"
            rules="required"
          >
            <v-select
              v-model="type"
              :options="type_options"
              placeholder="Pilih Jenis Media"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  VBModal,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  name: "keywordFormModal",
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    VBModal,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      required,
      name: null,
      type: null,
      type_options: ["Media Online", "Media Cetak", "Media Elektronik"],
    };
  },

  methods: {
    validationForm() {
      this.$refs.keywordForm.validate().then((success) => {
        if (success) {
          this.$bvModal.hide(this.data.id);
        }
      });
    },
  },

  directives: {
    "b-modal": VBModal,
  },
};
</script>