<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title class="mr-1">Blacklist Media</b-card-title>
      <!-- HELP ICON -->
      <feather-icon icon="HelpCircleIcon" size="20" class="mr-auto" />

      <!-- ADD MEDIA BUTTON -->
      <b-button
        variant="outline-primary"
        class="ml-auto mr-1"
        @click="addBlacklist"
      >
        <feather-icon icon="PlusIcon" class="mr-50" /> Tambah Media
      </b-button>

      <!-- SEARCH FORM -->
      <b-input-group class="input-group-merge w-25">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" class="text-primary" />
        </b-input-group-prepend>
        <b-form-input placeholder="Search" v-model="table_data.filter" />
      </b-input-group>
    </b-card-header>

    <!-- TABLE -->
    <mediaTable
      :data="table_data"
      :editData="editBlacklist"
      :deleteData="deleteBlacklist"
    />

    <!-- ADD MEDIA MODAL COMPONENT -->
    <mediaFormModal :data="modal_data" />

    <!-- EDIT MEDIA MODAL COMPONENT -->
    <mediaFormModal :data="edit_modal_data" />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import mediaTable from "./mediaTable.vue";
import mediaFormModal from "./mediaFormModal.vue";

export default {
  name: "cardWhitelist",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    mediaTable,
    mediaFormModal,
  },

  data: () => ({
    modal_data: {
      title: "Tambah Blacklist Media",
      type: "add-blacklist",
      id: "add-blacklist",
    },
    edit_modal_data: {
      title: "Edit Blacklist Media",
      type: "edit-blacklist",
      id: "edit-blacklist",
    },
    table_data: {
      type: "buletin",
      row: 5,
      filter: null,
      fields: [
        {
          key: "no",
          label: "No",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "2%" },
        },
        {
          key: "nama_media",
          label: "Nama Media",
          thClass: "text-left",
          tdClass: "text-left",
          sortable: true,
        },
        {
          key: "jenis_media",
          label: "Jenis Media",
          thClass: "text-center",
          tdClass: "text-center",
          sortable: true,
          thStyle: { width: "20%", paddingRight: "2rem" },
        },
        {
          key: "tanggal_input",
          label: "Tanggal Input",
          thClass: "text-center",
          tdClass: "text-center",
          sortable: true,
          thStyle: { width: "20%", paddingRight: "2rem" },
        },
        {
          key: "action",
          label: "Action",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "15%" },
        },
      ],
      items: [
        {
          id: 1,
          nama_media: "Indonesia.com",
          jenis_media: "Media Konvensional",
          tanggal_input: "09 September 2022",
        },
        {
          id: 2,
          nama_media: "Indonesia.com",
          jenis_media: "Media Konvensional",
          tanggal_input: "09 September 2022",
        },
        {
          id: 3,
          nama_media: "Indonesia.com",
          jenis_media: "Media Konvensional",
          tanggal_input: "09 September 2022",
        },
        {
          id: 4,
          nama_media: "Indonesia.com",
          jenis_media: "Media Konvensional",
          tanggal_input: "09 September 2022",
        },
        {
          id: 5,
          nama_media: "Indonesia.com",
          jenis_media: "Media Konvensional",
          tanggal_input: "09 September 2022",
        },
      ],
    },
  }),

  methods: {
    addBlacklist() {
      this.$bvModal.show("add-blacklist");
    },
    editBlacklist(id) {
      this.$bvModal.show("edit-blacklist");
    },
    deleteBlacklist(id) {
      alert(id);
    },
  },
};
</script>