<template>
  <div>
    <cardWhitelist />
    <cardBlacklist />
  </div>
</template>

<script>
import cardWhitelist from "@/components/setting/media/cardWhitelist.vue";
import cardBlacklist from "@/components/setting/media/cardBlacklist.vue";

export default {
  name: "Media",
  components: { cardWhitelist, cardBlacklist },

  data: () => ({
    title: "Media | Online Media Monitoring",
  }),

  created() {
    document.title = this.title;
  },
};
</script>